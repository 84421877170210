import React from 'react'

const AllNoticeComposition = (props) => {
  return (
    <div className='table-responsive d-flex justify-content-center align-items-center my-4'>
         <table className="table table-bordered table-striped table-hover table-sm w-75">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Caption Text</th>
                <th scope="col">Download PDF</th>
                <th scope="col">Published Date</th>
                </tr>
            </thead>
              <tbody>
                {props.children}
              </tbody>
          </table>
    </div>
  )
}

export default AllNoticeComposition