import React, { useEffect, useState } from 'react'
import SectionComposition from '../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'

import PageHeader from '../../../layouts/PageHeader'
import PageTitle from '../../../hooks/PageTitle'
import api from '../../../utils/axiosConfig'
import ProfileCardLayout from '../../../layouts/ProfileCardLayout'


const COE = () => {
 
   const [data, setData] = useState([]);

  //Fetch function start
  const fetchData = async () => {
    try {
      const res = await api.get(`all-office-employee?office_name=${'COE'}`);
      setData(res.data)
    } catch (error) {
      console.error(error);
    }
  }
  
  useEffect(() => {
    fetchData();
  },[])

  return (
    <>
      <PageTitle title="Controller of Examination"/>
      <PageHeader headerTitle = "Controller of Examination"/>
      <SectionComposition>
        <ResizeScreenComposition>
           <div className="row justify-content-center">
               {
                data.map((item, index) => 
                  <ProfileCardLayout key={index} path='office-epmployee/'  img={item.profile_img} name={item.employee_name} position={item.position} email={item.email} mobile={item.mobile} intercom = {item.intercom} room={item.room_no}/>
                )
               }
           </div>
        </ResizeScreenComposition>
      </SectionComposition>
    </>
  )
}

export default COE