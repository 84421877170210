import React from 'react'

const ProfileLazyImg = ({ src, alt }) => {
  const style = {
    width: '100%',
    height: 'auto',
    maxWidth: '170px'
  }
  return (
    <img
      className="border border-info rounded-circle"
      style={style}
      src={src}
      alt={alt}
      loading="lazy"
      onError={(e) => {
        // e.target.src = '/asset/img/default/default.png' // some replacement image
       }}
    />
  )
}

export default ProfileLazyImg


