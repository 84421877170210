import { lazy } from "react";
import { Suspense } from "react";
import { Row, Col } from "react-bootstrap"

import { NavLink } from "react-router-dom"


const LandscapeCardLayout = (props) => {

    const LazyImage = lazy(() => import('../redux/LazyImg'));


  return (
        <Row className="mt-5">
            <Col xl={3} className="border p-2">
                <div className="d-flex justify-content-center align-items-center">

                        <Suspense fallback={<div className='text-center'>Loading...</div>}>
                            <LazyImage src={process.env.REACT_APP_IMAGEBASE_URL+'award-image/'+props.img} alt={props.img} className='img-thumbnail'/>
                        </Suspense>
                </div>
            </Col>
            
            <Col xl={9} className="border  p-2">
                
                <h3><span dangerouslySetInnerHTML={{__html: props.captionText}} /></h3>
                <hr />
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-event" viewBox="0 0 16 16">
                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                </svg>
                <b> {props.createdAt.slice(0,10)}</b>
                <hr />
                <article className=''><span dangerouslySetInnerHTML={{__html: props.articleContent}} /></article>
                <NavLink to={`${props.id}`} className="float-end">View Article</NavLink>
            </Col>
        </Row>
  )
}

export default LandscapeCardLayout