import React, { useEffect, useState } from 'react'
import api from '../../utils/axiosConfig';
import SectionComposition from '../../layouts/SectionComposition';
import ResizeScreenComposition from '../../layouts/ResizeScreenComposition';
import { NavLink } from 'react-router-dom';
import NoDataFound from '../../errors/NoDataFound';

const Career = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get('all-career');
      setData(res.data)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect( ()=> {
    fetchData();
    localStorage.setItem('previousPathname', 'career/')
  },[])


  return (
    <SectionComposition>
      <ResizeScreenComposition>
         <div className="table-responsive">
         <table className='table table-sm table-bordered table-striped'>
            <thead>
              <tr>
              <th>SL</th>
              <th>Job Title</th>
              <th>Position</th>
              <th>Number of Post</th>
              <th>Educational Requirement</th>
              <th>Application Deadline</th>
              </tr>
            </thead>
            <tbody>
              {
                  data.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <NoDataFound errorCaption="No Circular is uploaded yet" />
                      </td>
                    </tr>
                  ) :
                    
               ( data.map((item, index) => 
                <tr key={index}>
                   <td>{index + 1}</td>
                   <td><NavLink to={`../view-pdf/${item.file}`} target="_blank">{item.job_title}</NavLink></td>
                   <td>{item.position}</td>
                   <td>{item.num_of_post}</td>
                   <td>{item.edu_requirement}</td>
                   <td>{item.deadline}</td>
                </tr>
                ))
              }
            </tbody>
         </table>
         </div>
      </ResizeScreenComposition>
    </SectionComposition>
  )
}

export default Career