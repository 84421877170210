import React from 'react'
import SectionComposition from '../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import ContentComposition from '../../../layouts/ContentComposition'
import { Col, Row } from 'react-bootstrap'
import LazyImg from '../../../redux/LazyImg'

const Future = () => {
  return (
    <SectionComposition>
       <ResizeScreenComposition>
          <ContentComposition>
               <Row>
                  <Col xl={8} className='px-2'>
                     <p>Welcome to Khwaja Yunus Ali Laboratory School and College! Prepare for endless possibilities, embrace challenges, believe in yourself, and dream big. Nurture your passions, be curious, and engage in extracurricular activities. Cultivate resilience, adaptability, and a growth mindset. Collaborate, take ownership of your education, and practice time management. Stay positive, be kind, and make a difference. Join us on this transformative journey, where success and fulfillment await. Welcome to an extraordinary future!
                        </p>
                  </Col>
                  <Col  xl={4} className='px-2'>
                      <LazyImg src='/asset/img/students/future.jpg'/>
                  </Col>
               </Row>
          </ContentComposition>
       </ResizeScreenComposition>
    </SectionComposition>
  )
}

export default Future