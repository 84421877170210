
import ListGroup from 'react-bootstrap/ListGroup'
import {NavLink } from "react-router-dom";
import "./NoticeLayout.css"

const NoticeLayout = (props) => {
  return (
    <ListGroup.Item as="li"className="border-0 p-0">
        <div className=" w-100">
          <div className="mb-3 d-flex justify-content-start"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#FF6347" className="bi bi-bell text-danger mt-1 me-1" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                </svg>
                <NavLink to={`view-pdf/${props.file}`}  className="text-decoration-none text-dark text-animation" title="Click to download file" target="_blank">  
                  <span dangerouslySetInnerHTML={{__html: props.captionText}} />
                </NavLink>
          </div>
          <div className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00D100" className="bi bi-calendar me-1 text-success" viewBox="0 0 16 16">
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
            </svg>
            <strong>{props.createdAt.slice(0, 10)}</strong>
          </div>
          <hr />
       </div>
  </ListGroup.Item>
  )
}
export default NoticeLayout