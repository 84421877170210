import React from 'react'
import {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import api from '../../../utils/axiosConfig';
import LandscapeCardDetailsLayout from '../../../layouts/LandscapeCardDetailsLayout';



const ViewProgramDetail = () => {

    const [data, setData] = useState([]);
    const {id} = useParams();

    const  fetchProgramDetail = async (id) =>{
        try{
            const res = await api.get(`row-event?id=${id}`);
            setData(res.data)
        }catch(error){
            console.error(error);
        }
     } 
     useEffect(() => {
        fetchProgramDetail(id);
      }, [id]);

  return (
        <>
            {
                data.map((item, index) => {
                    return <LandscapeCardDetailsLayout key={index} captionText = {item.caption_text} createdAt = {item.created_at} path='carousel/' img={item.img} textContent={item.text_content} />
                })
            }
        </>
  )
}

export default ViewProgramDetail