import React from 'react'

const StudentProfileLazyImg = ({ src, alt }) => {
  return (
    <img
      className="border border-info rounded-circle w-100"
      src={src}
      alt={alt}
      loading="lazy"
      onError={(e) => {
        e.target.src = '/asset/img/default/default.png' // some replacement image
       }}
       style={{maxWidth:"140px"}} 
    />
  )
}

export default StudentProfileLazyImg


