import React, { useEffect, useState } from 'react'
import api from '../../../../utils/axiosConfig';
import PageTitle from '../../../../hooks/PageTitle';
import PageHeader from '../../../../layouts/PageHeader';
import SectionComposition from '../../../../layouts/SectionComposition';
import ResizeScreenComposition from '../../../../layouts/ResizeScreenComposition';
import ContentComposition from '../../../../layouts/ContentComposition';


const HigherSecondary = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get(`row-academic?menu_type=${'higher-secondary'}`);
      setData(res.data)
      console.log(res.data)
    } catch (error) {
      console.error(error);
    }
  }
  useEffect( ()=> {
    fetchData();
  },[])


  return (
      <>
        <PageTitle title='Higher Secondary'/>
        <PageHeader headerTitle = 'Higher Secondary'/>
        <SectionComposition>
          <ResizeScreenComposition>
              <ContentComposition>
                  <span dangerouslySetInnerHTML={{__html: data.content}} />
              </ContentComposition>
          </ResizeScreenComposition>
        </SectionComposition>
      </>
  )
}

export default HigherSecondary