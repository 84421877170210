export const AchievementData = [
    {
        id: "1",
        img: "acheivement-1.jpg",
        category_name: "Awards and prizes",
        summary: "Congratulations to members of our community who have received awards and prizes for their research, professional or creative endeavours, and contributions to their communities"
    },
    {
        id: "2",
        img: "acheivement-2.jpg",
        category_name: "Academic Research",
        summary: "News about recent research grants and other funding"
    },
    {
        id: "3",
        img: "acheivement-3.jpg",
        category_name: "Board Result",
        summary: "Partnerships, gifts and achievements from across the University and our alumni"
    }

];