import React from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import { Row, Col } from 'react-bootstrap'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import ManagementProfile from '../../../layouts/ManagementProfile'
import SectionComposition from '../../../layouts/SectionComposition'
import ContentComposition from '../../../layouts/ContentComposition'

const Founder = () => {
  return (
    <>
        <PageTitle title="Founder" />
        <PageHeader headerTitle="Founder"/>
        <SectionComposition>
          <ResizeScreenComposition>
                <Row>
                    <Col lg={4}>
                          <ManagementProfile imgLayout="img-thumbnail" imgsrc='/asset/img/founder/founder.jpg' maxWidth="280px" name='Dr. M. M. Amjad Hussain' position='Founder Chairman' lifeCycleDate='1-Oct-1925 to 11-Sep-2012'/>
                    </Col>
                    <Col lg={8} className='px-2'>
                        <ContentComposition>
                            <div className='shadow p-3 mb-5 bg-white rounded'>
                              <p>
                                 Dr. M. M. Amjad Hussain, an entrepreneur with a most benevolent and compassionate personality, was born on 01 October 1925 in Enayetpur in the district of Sirajganj. He completed his primary education at a local Madrasha in his village. He was educated at Sthal Pakrashi Institution School & College and completed his secondary education there. After passing the Matriculation Examination in 1941, he was admitted to Rajshahi College and obtained his Intermediate of Science Certificate in 1943. He traveled to Kolkata and successfully enrolled himself into Kolkata Medical College to pursue a degree in medicine. He graduated in medicine in 1948. In 1952, he was awarded the Bachelor of Medicine degree and joined the Pakistan Army in its Medical Corps.                              </p>
                              <br/>
                              <p>
                                After serving the Army for three years, he took self-retirement and turned out to be an entrepreneur. He started with an export oriented industry named Bangladesh Kapok Mills Limited in 1955 in Chittagong. Soon after, he established Alhaj Textile Mills Limited at Ishwardi in 1962, Alhaj Jute Mills in 1967 at Sharishabari, Jamalpur. He was uncontested Chairman of the Bangladesh Textile Mills Association for many years. In 1982, he established a pharmaceutical company named Drug International Limited. The Advanced Technology and Ideas Ltd. (ATI), an IT firm was founded in 1994. In 1997, Drug International Basic Chemicals Division was set up. Afterwards, ATI Ceramics Ltd was launched in 2004 to manufacture floor and wall tiles. In addition, he became interested towards contributing to the development of tea industry in Bangladesh and later, established M. M. Tea Estate Ltd. in Panchagarh . M. M. Multi Fibers Ltd was then founded in 2006. Because of his being an entrepreneur of the group of companies and at the same time as one of the highest tax payers, he was nominated as Commercially Important Person (CIP) by the Ministry of Industries of the Bangladesh Government.
                              </p>
                              <br/>
                              <p>
                                Besides being a pioneering entrepreneur in many fields of trade and business, Dr. M. M. Amjad Hussain concentrated towards helping the deprived community of society. Realizing the importance and urgency of women’s empowerment, he thought of creating opportunities for women’s education and he established a girls’ school, Meher-un-Necha Girls High School in Enayetpur. In order to provide the much needed health facilities to the poor people of Bangladesh, he established a 500 bed hospital as a non-trading concern in Enayetpur by the name of his spiritual leader, a Muslim Saint HazratKhwajaYunus Ali (R) on 124 acres of land which is managed by specialist doctors, trained nurses along with utilization of modern equipment. The hospital is capable of providing healthcare of Treatment of all kind of diseases. In 2005, Khwaja Yunus Ali Medical College was founded to produce medical graduates at a private institution level that was able to draw students from home and abroad. Besides the Medical College, a Nursing College was established to produce skilled manpower for the demanding profession of the nursing.
                              </p>
                              <br/>
                              <p>
                                The KhwajaYunus Ali Laboratory School and college was brought into existence to facilitate children’s education of those living inside the campus and the general public living in the villages adjacent to it. The school is functioning with the national curricula of English version. Dr M.M. Amjad Hussain did not stop his endeavor for uplifting the fate of his fellow countrymen until he could complete founding KhwajaYunus Ali University.
                              </p>
                              <br/>
                              <p>
                                This extra ordinarily brilliant, disciplined, educated, honest, and patriotic person passed away on 11 September, 2012. May Allah bless his soul with eternal peace in Jannah!
                              </p>
                            </div>
                        </ContentComposition>
                    </Col>
                  </Row>
          </ResizeScreenComposition> 
         </SectionComposition>
    </>
  )
}

export default Founder