export const ChooseUsData = [
    {
        icon: '/accommodation.png',
        img: "/background.png",
        title: "Accommodation",
        article: "Khwaja Yunus Ali Laboratory School & College (KYALSC) provides accommodation facilities for both male and female students in the secure atmosphere. There are two students' dormitory - one for male and another for female students. Both have 256 students capacity respectively."
    },
    {
        icon: '/health-care.png',
        img: "/background.png",
        title: "Health-care",
        article: "KYALSC prioritizes the well-being of its students with health care services. On-campus medical facilities and qualified professionals ensure access to basic healthcare needs. It strives to provide a supportive environment for students' physical and mental health."
    },
    {
        icon: '/computer-lab.png',
        img: "/background.png",
        title: "Modern Computer Lab",
        article: "KYALSC's modern computer lab is a dynamic and cutting-edge facility equipped with state-of-the-art computers, software, and high-speed internet. It provides for students to engage in practical learning, and develop their technical skills."
    },
    {
        icon: '/laboratory.png',
        img: "/background.png",
        title: "Laboratory",
        article: "It boasts state of the art laboratories, where students engage in hands-on experiments and research. These modern laboratories serve as catalysts for scientific exploration, spirit of curiosity, innovation, preparing them for future scientific endeavors."
    },
    {
        icon: '/library.png',
        img: "/background.png",
        title: "Library",
        article: "The library at KYALSC serves as a valuable resource hub for students, researchers, and faculty members. It is a well-equipped that houses a vast collection of books, journals, research papers, and digital resources spanning various disciplines."
    },
    {
        icon: '/co-curricular-activities.png',
        img: "/background.png",
        title: "Co-Curricular Activities",
        article: "Khwaja Yunus Ali Laboratory School & College (KYALSC) highly inspires the co-curricular activities of the students. We celebrate our important red letter days of our cultural and history, and organize discussions and cultural programs as well."
    },
    {
        icon: '/transportation.png',
        img: "/background.png",
        title: "Transportation",
        article: "Transportation at Khwaja Yunus Ali Laboratory School and College (KYALSC) is well-organized and caters to the commuting needs of students. KYALSC provides shuttle services to ensure that students can easily travel between their accommodations and the university."
    },
    {
        icon: '/cafeterias.png',
        img: "/background.png",
        title: "Cafeterias",
        article: "The cafeterias at KYALSC provides a vibrant and inviting dining experience for students. With a focus on quality and variety, the university's cafeterias offer a wide range of delicious and nutritious food options to cater to diverse tastes and dietary preferences."
    },

]