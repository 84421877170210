import React from 'react'

const LazyImg = ({ src, alt }) => {
  return (
    <img
      className="d-block w-100"
      src={src}
      alt={alt}
      loading="lazy"
    />
  )
}

export default LazyImg





