

import { Row } from 'react-bootstrap'
import BreadCrumb from '../components/BreadCrumb/BreadCrumb'
import "./PageHeader.css"


const PageHeader = (props) => {
  return (

      <Row className="d-flex min-vh-25 justify-content-center align-items-center page-header"  style={{height: "260px"}} data-aos="fade-down">
          <div className="page-title w-75 d-flex flex-column align-items-center">
              <h1 className='text-uppercase text-white'>{props.headerTitle}</h1>
              <BreadCrumb />
          </div>
      </Row>

  )
}

export default PageHeader