import React, { useEffect, useState } from 'react'
import api from '../../../../utils/axiosConfig';
import PageTitle from '../../../../hooks/PageTitle';
import PageHeader from '../../../../layouts/PageHeader';
import SectionComposition from '../../../../layouts/SectionComposition';
import ResizeScreenComposition from '../../../../layouts/ResizeScreenComposition';
import ContentComposition from '../../../../layouts/ContentComposition';
import ImageFrameLayout from '../../../../layouts/ImageFrameLayout';

const ArtCraft = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get(`all-club?club_name=${'Art-Craft'}`);
      setData(res.data)
    } catch (error) {
      console.error(error);
    }
  }
  useEffect( ()=> {
    fetchData();
  },[])

  return (
      <>
        <PageTitle title='ArtCraft'/>
        <PageHeader headerTitle = 'ArtCraft'/>
        <SectionComposition>
          <ResizeScreenComposition>
              <ContentComposition>
              
                      <div className="row">
                          {
                            data.map((item, index) => 
                              <ImageFrameLayout key={index}  path='club-image/' img={item.img} captionText={item.caption_text}/>
                            )
                          }
                      </div>
                
              </ContentComposition>
          </ResizeScreenComposition>
        </SectionComposition>
      </>
  )
}

export default ArtCraft