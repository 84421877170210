import React from 'react'
import "./Heading.css"

const Heading = (props) => {
  return (
    <div className="heading">
        <h3 className='text-center text-uppercase lead fs-4' data-aos="zoom-in">{props.headingTitle}</h3>
        <div className="heading-line"  data-aos="zoom-in"></div>
    </div>
  )
}

export default Heading