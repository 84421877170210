import React from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import SectionComposition from '../../../layouts/SectionComposition'
import ContentComposition from '../../../layouts/ContentComposition'


const History = () => {
  return (
    <>
        <PageTitle title="History" />
        <PageHeader headerTitle="History"/>
        <SectionComposition bgColor="">
          <ResizeScreenComposition>
              <ContentComposition>
                    <p>
                       Khawja Yunus Ali Laboratory School and College was founded in 2009 in Enayetpur, Chouhali, Sirajganj, in northwest Bangladesh. Dr. M. M. Amjad Hussain was determined to establish it with the goal of providing quality education and holistic development opportunities to students in the region.                    </p>
                    <br/>
                    <p>
                       The name of the institution is after Hazrat Khwaja Yunus Ali Enapetpur (R), a renowned spiritual leader, educationist, and social reformer. 
                    </p>
                    <br />
                    <p>
                        Khawja Yunus Ali Laboratory School and College has been growing steadily since its inception, expanding its facilities, curriculum, and student body. It began as a small educational institution with a few classrooms and limited resources, but through dedicated efforts and the support of the community, it has evolved into a prominent educational institution in the region.
                    </p>
                    <br />
                    <p>
                        The school and college have consistently strived for academic excellence, offering a comprehensive curriculum that encompasses various academic disciplines. The institution has hired faculty members who are highly qualified and dedicated and committed to nurturing the intellectual curiosity and academic potential of students.
                    </p>
                    <br />
                    <p>
                        Khawja Yunus Ali Laboratory School and College has consistently stressed the importance of character development in addition to academic pursuits over the years. The institution believes in instilling strong moral values, ethical principles, and social responsibility in its students. The institution's goal is to develop well-rounded individuals who are not only academically proficient but also compassionate and socially conscious through various co-curricular activities, leadership programs, and community engagement initiatives.
                    </p>
                    <br />
                    <p>
                       The institution has embraced technological advances, integrating technology into its educational methodologies and infrastructures. It has established modern computer labs, multimedia classrooms, and other facilities to enhance the learning experience and equip students with the necessary digital literacy skills for the 21st century.
                    </p>
                    <br />
                    <p>
                       Throughout its history, the Khawja Yunus Ali Laboratory School and College fostered a culture of continual improvement and innovation. The organization has undergone regular evaluations and taken proactive measures to improve its educational offerings, infrastructure, and overall student experience by implementing strategic initiatives. The institution has actively sought feedback from students, parents and other stakeholders to ensure that its programs and services are aligned with the evolving needs of the educational landscape.
                    </p>
                    <br />
                    <p>
                       Today, Khawja Yunus Ali Laboratory School and College is a respected educational institution, known for its academic excellence, character development programs, and commitment to holistic education. It continues to inspire generations of students, preparing them to excel academically, contribute to society and be responsible citizens of the world.
                    </p>
              </ContentComposition>
          </ResizeScreenComposition>
        </SectionComposition>
    </>
  )
}

export default History