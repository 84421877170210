import React from 'react'

const ManagementProfile = (props) => {
  return (
    <>
    <div className='border border-info d-flex flex-column justify-content-center align-items-center py-3' data-aos="zoom-in">
       <img src={props.imgsrc} className='img-thumbnail' style={{maxWidth: props.maxWidth, height: props.height}} alt={props.name} />
       <div className="text-center mt-3">
           <h6 className='fs-5'>{props.name}</h6>
           {props.position && <span>{props.position}</span>}
           {props.designition && <small>{props.designition}</small>}
             <br/>
             <small>Khwaja Yunus Ali Laboratory School & College</small> 
            {props.dept && <h6>{props.dept}</h6>}
            <br/>
           {props.lifeCycleDate && <small>{props.lifeCycleDate}</small>}
       </div>
     </div> 
   </>
  )
}

export default ManagementProfile