import React from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import Heading from '../../../layouts/Heading'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import SectionComposition from '../../../layouts/SectionComposition'
import ContentComposition from '../../../layouts/ContentComposition'
import { AccreditationData } from './AccreditationData'
import SerialLinkLayout from '../../../layouts/SerialLinkLayout'


const Accreditation = () => {
  return (
    <>
        <PageTitle title="Accreditation" />
        <PageHeader headerTitle="Accreditation"/>
        <SectionComposition bgColor=''>
          <ResizeScreenComposition>
              <ContentComposition>
                <Heading headingTitle = "We are accredited by"/>
                  {
                    AccreditationData.map((item, index)=> 
                      <SerialLinkLayout key={index} sl={index + 1} name={item.name} link = {item.link} />
                    )
                  }
              </ContentComposition>
          </ResizeScreenComposition>
        </SectionComposition>

    </>
  )
}

export default Accreditation