import React, { Suspense, useEffect, useState } from 'react'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import { NavLink } from 'react-router-dom'
import './StudentInfo.css';
import SectionComposition from '../../../layouts/SectionComposition';
import api from '../../../utils/axiosConfig';
import StudentProfileLazyImg from '../../../redux/StudentProfileLazyImg';


const StudentInfo = () => {
    const [classData, setClassData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [studentsData, setStudentsData] = useState([]);

    const fetchClassNames = async () => {
        try {
          const res = await api.get('all-class-names');
          setClassData(res.data)
        } catch (error) {
          console.error(error);
        }
      }

    const fetchGroupNames = async (e) => {
        setSelectedClass(e.target.value)
        try {
          const res = await api.get(`get-group-name-by-class-name?group_name_id= ${e.target.value}`);
          setGroupData(res.data)
        } catch (error) {
          console.error(error);
        }
      }

      useEffect(()=> {
        fetchClassNames();
        localStorage.setItem('previousPathname', 'students-results/')
      },[])

    const handleSubmit = async () => {
        try {
            const res = await api.get(`get-students-by-class-name?class_name_id=${selectedClass}&&group_name_id=${selectedGroup}`);
            setStudentsData(res.data)
        }catch (error) {
            console.error(error);
          }
    }  


  return (
    <ResizeScreenComposition>
      <SectionComposition>

        <div className="row">
            <div className="col-md-4 p-1">
                <select onChange={fetchGroupNames} value={selectedClass} className="form-select form-select-sm" aria-label="Default select example">
                    <option value="">Please, Select a Class</option>
                    {
                        classData.map((item, index)=>
                          <option value={item.id} key={index}>{item.class_name}</option>
                        )
                    }
                </select>
            </div>
            <div className="col-md-4 p-1">
               <select  onChange={(e) => setSelectedGroup(e.target.value)}  value={selectedGroup} className="form-select form-select-sm" aria-label="Default select example">
                    <option value="">Please, Select a group</option>
                    {
                        groupData.map((item, index)=>
                          <option key={index} value={item.id}>{item.group_name}</option>
                        )
                    }
                </select>
            </div>
            <div className="col-md-4 p-1">
                <input type='submit' onClick={handleSubmit} value='Search' className='btn btn-sm btn-outline-success w-100'/>
            </div>
        </div>


        <hr />

     


    <h2>Showing Result: {studentsData.length} Students</h2>
      {
        studentsData.map((item, index) => 
            <div className="border my-2" key={index}>
                <div className="row">
                    <div className="col-md-2 text-center">
                        <div className="d-flex justify-content-around align-items-center" style={{height: '140px'}}>
                            <div className="text-center">
                                <h6>Role No</h6>
                                <h3>{item.student_role}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="personal-information row border d-flex justify-content-center align-items-center">
                            <div className="col-md-3 text-center py-1">
                            {/* <img src={process.env.REACT_APP_IMAGEBASE_URL+'students/'+item.profile_img} alt={item.profile_img} className='w-100 rounded-circle' style={{maxWidth:"140px"}}/> */}
                            <Suspense fallback={<div className='text-center'>Loading...</div>}>
                               <StudentProfileLazyImg src={process.env.REACT_APP_IMAGEBASE_URL+'students/'+item.profile_img} alt={item.profile_img}/>
                            </Suspense>
                            </div>
                            <div className='col-md-6 px-2'>
                                <table className='student-personal-info mx-auto w-100'>
                                <tbody>
                                    <tr>
                                        <td>Student Name</td>
                                        <td>:</td>
                                        <td>{item.student_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Father's Name</td>
                                        <td>:</td>
                                        <td>{item.father_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Mother's Name</td>
                                        <td>:</td>
                                        <td>{item.mother_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Student Religion</td>
                                        <td>:</td>
                                        <td>{item.religion}</td>
                                    </tr>
                                    <tr>
                                        <td>Student Gender</td>
                                        <td>:</td>
                                        <td>{item.gender}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>

                            <div className="col-md-3 text-center py-1">
                                <NavLink to={`/view-pdf/${item.student_result}`} target="_blank">
                                    <input type="image" src="/asset/img/students/result-button.png" alt="Submit" className='w-100' style={{maxWidth: '180px'}}  />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="d-flex justify-content-around align-items-center" style={{height: '140px'}}>
                        <div className='text-center'>
                                <h6>Student ID</h6>
                                <h3>{item.student_id_num}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )    
   }

        </SectionComposition>
    </ResizeScreenComposition>
  )
}

export default StudentInfo