import React, { useEffect, useState } from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import SectionComposition from '../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import ContentComposition from '../../../layouts/ContentComposition'
import api from '../../../utils/axiosConfig'
import ManagementProfile from '../../../layouts/ManagementProfile'
import { Row, Col } from 'react-bootstrap'
import Heading from '../../../layouts/Heading'
 

const InternationalRelation = () => {
  const [IRData, setIRData] = useState([]);

  //Fetch function start
  const fetchCarousel = async () => {
      try {
        const res = await api.get('row-ir');
        setIRData(res.data)
        console.log(res.data)
      } catch (error) {
        console.error(error);
      }
    }

    useEffect(() => {
      fetchCarousel();
    },[])

  return (
    <>
        <PageTitle title="International Relation" />
        <PageHeader headerTitle="International Relation"/>
        <SectionComposition bgColor=''>
          <ResizeScreenComposition>
              <ContentComposition>
                   <span dangerouslySetInnerHTML={{__html: IRData.content}} />
              </ContentComposition>

     
              <Row>
                    <Col lg={4}>
                          <ManagementProfile imgLayout="img-thumbnail" imgsrc={process.env.REACT_APP_IMAGEBASE_URL+'ir/'+IRData.profile_img} maxWidth="280px" name={IRData.name} position={IRData.position} />
                    </Col>
                    <Col lg={8} className='px-2'>
                        <ContentComposition>
                            <div className='shadow p-3 mb-5 bg-white rounded'>
                                <Heading headingTitle="Message from The Advisor"/>
                                <span dangerouslySetInnerHTML={{__html: IRData.msg}} />
                            </div>
                        </ContentComposition>
                    </Col>
                  </Row>
            
          </ResizeScreenComposition>
        </SectionComposition>
    </>
  )
}

export default InternationalRelation