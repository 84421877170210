import React, { useEffect, useState } from 'react'
import api from '../../utils/axiosConfig';
import SectionComposition from '../../layouts/SectionComposition';
import ResizeScreenComposition from '../../layouts/ResizeScreenComposition';
import PageHeader from '../../layouts/PageHeader';
import PageTitle from '../../hooks/PageTitle';
import ContentComposition from '../../layouts/ContentComposition';


const Library = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get('row-library');
      setData(res.data)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect( ()=> {
    fetchData();
  },[])


  return (
      <>
        <PageTitle title='Library'/>
        <PageHeader headerTitle = 'Library'/>
        <SectionComposition>
          <ResizeScreenComposition>
              <ContentComposition>
                  <span dangerouslySetInnerHTML={{__html: data.content}} />
              </ContentComposition>
          </ResizeScreenComposition>
        </SectionComposition>        
      </>
  )
}

export default Library