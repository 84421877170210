import { useState, useEffect, lazy, Suspense } from 'react'
import api from '../../../utils/axiosConfig';
import { NavLink } from 'react-router-dom';
const LazyImage = lazy(() => import('../../../redux/LazyImg'));


const ViewAllProgram = () => {

    const [data, setData] = useState([]);

    const  fetchAllProgram = async () =>{
        try{
            const res = await api.get('all-event');
            setData(res.data)
        }catch(error){
            console.error(error);
        }
     } 

    useEffect(()=>{
        fetchAllProgram();
    },[])

  return (
    <div className='table-responsive d-flex justify-content-center align-items-center my-4'>
    <table className="table table-bordered table-striped table-hover table-sm w-75">
       <thead>
           <tr>
           <th scope="col">#</th>
           <th scope="col">Program Photo</th>
           <th scope="col">Caption</th>
           <th scope="col">Read more</th>
           </tr>
       </thead>
       <tbody>
        {
            data.map( (item, index) => (
                <tr key={index}>
                <td>{index + 1}</td>
                <td width={'200px'}> 
                <Suspense fallback={<div className='text-center'>Loading...</div>}>
                   <LazyImage src={process.env.REACT_APP_IMAGEBASE_URL+'carousel/'+item.img} alt={item.img} />
                </Suspense>
                </td>
                <td>{item.caption_text}</td>
                <td className='text-center'>
                    <NavLink to={'./'+item.id}>
                        Read more
                    </NavLink>
                </td>
               </tr>
            ))
        }

       </tbody>
     </table>
</div>
  )
}

export default ViewAllProgram