import { useState,useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RootContext from './context/RootContext';
import AppRoutes from './routes/AppRoutes';

import AOS from "aos";
import "aos/dist/aos.css";

import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

// import { MessengerChat } from 'react-messenger-chat-plugin'

function App() {
  //Use Context State
  const [loading, setLoading] = useState(true);
  const [year , setYear] = useState();

  const getFullYear = ()=> {
      setYear(new Date().getFullYear())
  }

  useEffect(() => {
    getFullYear();
    AOS.init({
        offset: 100, // offset (in px) from the original trigger point
        duration: 1500, // values from 0 to 3000, with step 50ms
        easing: "ease",
    });
}, []);



  return (
      <BrowserRouter>
        <RootContext.Provider
              value={{
                  loading,
                  setLoading,
                  year,
              }}
              >
            <AppRoutes />
            {/* <MessengerChat
              pageId="1800830940135318" // Replace with your page ID
              themeColor={"#000000"}
              loggedInGreeting="Hello, how can I help you today?"
              loggedOutGreeting="Please log in to chat"
            /> */}
        </RootContext.Provider>  
      </BrowserRouter>
  );
}

export default App;
