export const AccreditationData = [
    {
        name: "Ministry of Education",
        link: "http://www.moedu.gov.bd/",
    },
    {
        name: "Bangladesh Accreditation Council",
        link: "http://www.bac.gov.bd/",
    },
    {
        name: "University Grants Commission",
        link: "http://www.ugc.gov.bd/",
    },
    {
        name: "Bangladesh Bar Council",
        link: "https://www.barcouncil.gov.bd/",
    },
    {
        name: "Bangladesh Bureau of Educational Information and Statistics",
        link: "http://www.banbeis.gov.bd/",
    },
    {
        name: "Pharmacy Council",
        link: "http://www.pcb.gov.bd/",
    }
]
