import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../../Loader/Loader';
import SectionComposition from '../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import PageHeader from '../../../layouts/PageHeader'
import PageTitle from '../../../hooks/PageTitle'
import api from '../../../utils/axiosConfig'
import ProfileCardLayout from '../../../layouts/ProfileCardLayout'
import Swal from 'sweetalert2';


const Secondary = () => {
 
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [value, setValue] = useState('');
 

  useEffect(() => {
    let timeoutId = null;

    if (value) {
      timeoutId = setTimeout(() => {
        api.get(`all-teachers?dept_id=${1}&filter_text=${value}`).then((res)=>{
      
        if(res.data.total){
          setData(res.data.data)
        }else{
          Swal.fire({
            icon: 'info',
            title: 'Sorry...',
            text: 'No record found!',
          }).then(() => {
            handleClear()
          })
        }
      })
      }, 500);
    }else{
      api.get(`all-teachers?dept_id=${2}&page=${page}`).then((res)=>{
        setData((prevData) => prevData.concat(res.data.data))
        if (res.data.data.length === 0) {
          setHasMore(false);
        }
      })
    }
    // Clear the timeout when the component unmounts or the value changes
    return () => clearTimeout(timeoutId);
  }, [value,page]);

  const handleSearch = (e) => {
    setValue(e.target.value);
  };

  const handleClear = () => {
    setValue('');
  }

  const clearButton = {
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    background: 'none',
    border: 'none',
    fontSize: '1',
  }

  return (
    <>
      <PageTitle title="Secondary (School) - Teachers"/>
      <PageHeader headerTitle = "Secondary (School) - Teachers"/>
      <SectionComposition>
        <ResizeScreenComposition>
          <InfiniteScroll
            dataLength={data.length}
            next={() => setPage(page + 1)}
            hasMore={hasMore}
            loader={<Loader/>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            } 
          >
           <div className="row justify-content-center">

               <div className="position-relative border">
                    <input type="text" value={value} onChange={handleSearch} className="form-control form-control-sm" placeholder='Type to search by Name' />
                    {value && (
                    <button className="position-absolute" style={clearButton} onClick={handleClear}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    </button>)
                    }
                </div>

               {
                data.map((item, index) => 
                  <ProfileCardLayout key={index} path='teachers/'  img={item.profile_img} name={item.member_name} position={item.position} interestSubject={item.research_interest} email={item.email} mobile={item.mobile} intercom = {item.intercom} room={item.room}/>
                )
               }
           </div>

           </InfiniteScroll>
        </ResizeScreenComposition>
      </SectionComposition>
    </>
  )
}

export default Secondary