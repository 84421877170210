import React, {useContext, useEffect, Suspense, lazy} from 'react'
import { Routes, Route} from 'react-router-dom'
import FrontendLayout from "./FrontendLayout"
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import RootContext from '../context/RootContext'
import Loader from '../Loader/Loader'
import Home from '../views/MainMenu/Home/Home'

import Program from '../views/MainMenu/Home/FrontBox/Program'
import TutionFee from '../views/MainMenu/Home/FrontBox/TutionFee'
import Scholarship from '../views/MainMenu/Home/FrontBox/Scholarship'
import Details from '../views/MainMenu/Home/FrontBox/Details'

import ViewAllNotices from '../views/MainMenu/Home/ViewAllNotices'
import ViewAllProgram from '../views/MainMenu/Home/ViewAllProgram'
import SubRouteOutlet from './SubRouteOutlet'
import ViewProgramDetail from '../views/MainMenu/Home/ViewProgramDetail'

import History from '../views/MainMenu/About/History'
import BackgroundKYALSC from '../views/MainMenu/About/BackgroundKYALSC'
import MissionVission from '../views/MainMenu/About/MissionVission'
import Founder from '../views/MainMenu/About/Founder'
import BOT from '../views/MainMenu/About/BOT'
import Accreditation from '../views/MainMenu/About/Accreditation'
import InternationalRelation from '../views/MainMenu/About/InternationalRelation'
import Achievement from '../views/MainMenu/About/Achievement'
import AcheivementList from '../views/MainMenu/About/AcheivementList'
import AcheivementListDetails from '../views/MainMenu/About/AcheivementListDetails'

import Photo from '../views/MainMenu/About/gallery/Photo'
import Tour from '../views/MainMenu/About/gallery/Tour'
import Video from '../views/MainMenu/About/gallery/Video'

import Chairman from '../views/MainMenu/Authority/Messages/Chairman'
import ViceChairman from '../views/MainMenu/Authority/Messages/ViceChairman'
import PrincipalMsg from '../views/MainMenu/Authority/Messages/Principal'
import HeadTeacherMsg from '../views/MainMenu/Authority/Messages/HeadTeacher'
 
import Prospectus from '../views/MainMenu/Admission/Prospectus'
import HigherSecondary from '../views/MainMenu/Admission/HigherSecondary'
import Secondary from '../views/MainMenu/Admission/Secondary'
import Primary from '../views/MainMenu/Admission/Primary'
import PrePrimary from '../views/MainMenu/Admission/PrePrimary'
import AdmissionForm from '../views/MainMenu/Admission/AdmissionForm'
import TransferMethod from '../views/MainMenu/Admission/TransferMethod'

import Policy from '../views/MainMenu/Academic/Policy/Policy'
import RulesRegulation from '../views/MainMenu/Academic/Policy/RulesRegulation'

import HigherSecondaryAcademic from '../views/MainMenu/Academic/Section/HigherSecondary'
import SecondaryAcademic from '../views/MainMenu/Academic/Section/Secondary'

import PrimaryAcademic from '../views/MainMenu/Academic/Section/Primary'
import PrePrimaryAcademic from '../views/MainMenu/Academic/Section/PrePrimary'

import Calendar from '../views/MainMenu/Academic/Contents/Calendar'
import HolidayList from '../views/MainMenu/Academic/Contents/HolidayList'
import Magazine from '../views/MainMenu/Academic/Contents/Magazine'
import Brochure from '../views/MainMenu/Academic/Contents/Brochure'
import Newsletters from '../views/MainMenu/Academic/Contents/Newsletters'

import TextBooks from '../views/MainMenu/Academic/Syllabus/TextBooks'
import Syllabus from '../views/MainMenu/Academic/Syllabus/Syllabus'
import HouseActivities from '../views/MainMenu/Academic/Syllabus/HouseActivities'

import Principal from '../views/MainMenu/Offices/Principal'
import HeadTeacher from '../views/MainMenu/Offices/HeadTeacher'
import COE from '../views/MainMenu/Offices/COE'
import Account from '../views/MainMenu/Offices/Account'
import AdmissionOffice from '../views/MainMenu/Offices/Admission'
import LibraryOffice from '../views/MainMenu/Offices/Library'
import Taekwondo from '../views/MainMenu/Offices/Taekwondo' 
import SportCulture from '../views/MainMenu/Offices/SportCulture'
import Lab from '../views/MainMenu/Offices/Lab'

import HigherSecondaryTeachers from '../views/MainMenu/People/HigherSecondary';
import SecondaryTeachers from '../views/MainMenu/People/Secondary';
import PrimaryTeachers from '../views/MainMenu/People/Primary';
import PrePrimaryTeachers from '../views/MainMenu/People/PrePrimary';

import StudentInfo from '../views/MainMenu/Student/StudentInfo'
import Campus from '../views/MainMenu/Student/Life/Campus'
import Facility from '../views/MainMenu/Student/Life/Facility'
import ClassRoom from '../views/MainMenu/Student/Life/ClassRoom'
import StudentLab from '../views/MainMenu/Student/Life/Lab'

import TaekwondoClub from '../views/MainMenu/Student/Club/Taekwondo'
import ArtCraft from '../views/MainMenu/Student/Club/ArtCraft' 
import Scout from '../views/MainMenu/Student/Club/Scout'
import Language from '../views/MainMenu/Student/Club/Language'
import Cultural from '../views/MainMenu/Student/Club/Cultural'
import Sports from '../views/MainMenu/Student/Club/Sports'
import Debating from '../views/MainMenu/Student/Club/Debating'

import College from '../views/MainMenu/Student/Dress/College'
import School from '../views/MainMenu/Student/Dress/School'
import PrimaryDress from '../views/MainMenu/Student/Dress/Primary'
import PrePrimaryDress from '../views/MainMenu/Student/Dress/PrePrimary'

import Future from '../views/MainMenu/Student/Future'
import Current from '../views/MainMenu/Student/Current'

import AcademicResult from '../views/MainMenu/Result/Academic'
import PublicResult from '../views/MainMenu/Result/Public'

import Career from '../views/TopMenu/Career'
import Library from '../views/TopMenu/Library'



const BuildingDirectory = lazy(() => import('../views/TopMenu/BuildingDirectory'));
const Corner = lazy(() => import('../views/TopMenu/Corner'));
const HelpDesk = lazy(() => import('../views/TopMenu/HelpDesk'));
const SiteMap = lazy(() => import('../views/TopMenu/SiteMap'));

const PDFView = lazy(() => import('../views/PDFView'));

const Contact = lazy(() => import('../views/MainMenu/Contact/Contact'));
const Error404 = lazy( () => import('../errors/Error404'))
const SubMenuLayout = lazy(() => import('./SubMenuLayout'))


const AppRoutes = () => {

  const {loading, setLoading } =  useContext(RootContext)

  useEffect( () => {
    setLoading(false);
  },[setLoading])

  return loading ? <Loader /> : (
    <Suspense fallback={<Loader />}>
      <ScrollToTop>
        <Routes>
          <Route path="/*" element={<FrontendLayout />}>
              <Route index element={<Home />}/>
              <Route path="program" element={<Program />}/>
              <Route path="tution-fee" element={<TutionFee />}/>
              <Route path="scholarship" element={<Scholarship />}/>
              <Route path="view-all-events-programs/*" element={<SubRouteOutlet />}>
                <Route index element={<ViewAllProgram />} />
                <Route path=":id" element={<ViewProgramDetail/>} />
              </Route>
              <Route path="view-pdf/:id" element={<PDFView />}/>
              <Route path="view-all-notices" element={<ViewAllNotices />}/>
              <Route path="details/:id" element={<Details />}/>

              {/* About Menu Section */}
              
              <Route path="about/" element={<SubMenuLayout />}>
                  <Route path="history" element={<History />}/>
                  <Route path="background-kyalsc" element={<BackgroundKYALSC />}/>   
                  <Route path="mission-vision" element={<MissionVission />}/>
                  <Route path="accreditation" element={<Accreditation/>}/>
                  <Route path="international-relation" element={<InternationalRelation/>}/>                 
                  <Route path="*" element={<Error404 />} />
              </Route>

              <Route path="authority/" element={<SubMenuLayout />}>
                 <Route path="founder" element={<Founder />}/>
                 <Route path="governing-body" element={<BOT/>}/>
                 <Route path="messages/" element={<SubMenuLayout />}>
                   <Route path="chairman" element={<Chairman/>}/>
                   <Route path="vice-chairman" element={<ViceChairman/>}/>
                   <Route path="principal" element={<PrincipalMsg/>}/>
                   <Route path="head-teacher" element={<HeadTeacherMsg/>}/>
                 </Route>
              </Route>

              <Route path="admission/" element={<SubMenuLayout />}>
                 <Route path="prospectus" element={<Prospectus />}/>
                 <Route path="higher-secondary" element={<HigherSecondary />}/>
                 <Route path="secondary" element={<Secondary />}/>
                 <Route path="primary" element={<Primary />}/>
                 <Route path="pre-primary" element={<PrePrimary />}/>
                 <Route path="admission-form" element={<AdmissionForm />}/>
                 <Route path="transfer-method" element={<TransferMethod />}/>
              </Route>

              <Route path="academic/" element={<SubMenuLayout />}>
                 <Route path="policy" element={<Policy />}/>
                 <Route path="rules-regulation" element={<RulesRegulation />}/>

                 <Route path="higher-secondary" element={<HigherSecondaryAcademic />}/>
                 <Route path="secondary" element={<SecondaryAcademic />}/>
                 <Route path="primary" element={<PrimaryAcademic />}/>
                 <Route path="pre-primary" element={<PrePrimaryAcademic />}/>

                 <Route path="calendar" element={<Calendar />}/>
                 <Route path="holiday-list" element={<HolidayList />}/>
                 <Route path="magazine" element={<Magazine />}/>
                 <Route path="brochure" element={<Brochure />}/>
                 <Route path="news-letters" element={<Newsletters />}/>

                 <Route path="text-books" element={<TextBooks />}/>
                 <Route path="syllabus" element={<Syllabus />}/>
                 
              </Route>

              <Route path="co-curricular-activities/" element={<SubMenuLayout />}>
                 <Route path="house-activities" element={<HouseActivities />}/>
                 <Route path="club/" element={<SubMenuLayout />}>
                     <Route path="taekwondo" element={<TaekwondoClub />}/>
                     <Route path="art-craft" element={<ArtCraft />}/>
                     <Route path="language" element={<Language />}/>
                     <Route path="cultural" element={<Cultural />}/>
                     <Route path="sports" element={<Sports />}/>
                     <Route path="scout" element={<Scout />}/>
                     <Route path="debating" element={<Debating />}/>
                  </Route>
                 <Route path="achievement/*" element={<SubRouteOutlet />}>
                      <Route index element={<Achievement />} />
                      <Route  path=":id/*" element={<SubRouteOutlet />}>
                        <Route index element={<AcheivementList />} />
                        <Route path=":id" element={<AcheivementListDetails />} />
                      </Route>
                  </Route> 
                  <Route path="corner" element={<Corner />}/>
              </Route>

              <Route path="offices/" element={<SubMenuLayout />}>
                 <Route path="principal" element={<Principal />}/>
                 <Route path="head-teacher" element={<HeadTeacher />}/>
                 <Route path="controller-of-examination" element={<COE />}/>
                 <Route path="account" element={<Account />}/>
                 <Route path="admission" element={<AdmissionOffice />}/>
                 <Route path="library" element={<LibraryOffice />}/>
                 <Route path="taekwondo" element={<Taekwondo />}/>
                 <Route path="sport-culture" element={<SportCulture />}/>
                 <Route path="lab" element={<Lab />}/>
              </Route>

              <Route path="people/" element={<SubMenuLayout />}>
                 <Route path="higher-secondary" element={<HigherSecondaryTeachers />}/>
                 <Route path="secondary" element={<SecondaryTeachers />}/>
                 <Route path="primary" element={<PrimaryTeachers />}/>
                 <Route path="pre-primary" element={<PrePrimaryTeachers />}/>
              </Route>

              <Route path="students/" element={<SubMenuLayout />}>
                  <Route path="information" element={<StudentInfo />}/>
                  <Route path="student-life/" element={<SubMenuLayout />}>
                     <Route path="campus" element={<Campus />}/>
                     <Route path="facility" element={<Facility />}/>
                     <Route path="class-room" element={<ClassRoom />}/>
                     <Route path="lab" element={<StudentLab />}/>
                  </Route>
                  <Route path="dress-code/" element={<SubMenuLayout />}>
                     <Route path="college" element={<College />}/>
                     <Route path="school" element={<School />}/>
                     <Route path="primary" element={<PrimaryDress />}/>
                     <Route path="pre-primary" element={<PrePrimaryDress />}/>
                  </Route>

                 <Route path="current" element={<Current />}/>
                 <Route path="future" element={<Future />}/>
              </Route>

              <Route path="result/" element={<SubMenuLayout />}>
                 <Route path="academic" element={<AcademicResult />}/>
                 <Route path="public" element={<PublicResult />}/>
              </Route>

               {/* Gallery */}
               <Route path="photo-gallery" element={<Photo />}/>
               <Route path="tour-gallery" element={<Tour />}/>
               <Route path="video-gallery" element={<Video />}/>

              <Route path="contact" element={<Contact />}/>

               {/* Top Menu Section */}
               <Route path="library" element={<Library />}/>
               <Route path="building-directory" element={<BuildingDirectory />}/>
              <Route path="career" element={<Career />}/>
              <Route path="help-desk" element={<HelpDesk />}/>
              <Route path="site-map" element={<SiteMap />}/>


              <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
    </ScrollToTop>
  </Suspense>
  )
}

export default AppRoutes