import React from 'react'
import OpenNewTab from '../hooks/OpenNewTab';
import { NavLink } from 'react-router-dom';


const SerialLinkLayout = (props) => {
    const {openInNewTab} = OpenNewTab();
  return (
        <p className="mt-3">
            <span className="border rounded-pill p-1 border-info me-2">{props.sl}</span> 
            <NavLink  to='#' onClick={(e) => openInNewTab(props.link)} className="text-decoration-none">{props.name}</NavLink>
        </p>
  )
}

export default SerialLinkLayout