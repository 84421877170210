import React,{ Suspense, lazy } from 'react'

import ResizeScreenComposition from './ResizeScreenComposition'
const LazyImage = lazy(() => import('../redux/LazyImg'));

const LandscapeCardDetailsLayout = (props) => {
  return (
   <ResizeScreenComposition>
      <div className='py-4'>
          <h1 className='lead fs-1'><span dangerouslySetInnerHTML={{__html: props.captionText}} /></h1>
      </div>
      <div className='row border'>
        <div className="col-sm-2 text-center">
          <td>{props.createdAt}</td>
          <hr />
        </div>
     
        <div className="col-sm-10">
            <Suspense fallback={<div className='text-center'>Loading...</div>}>
                <LazyImage src={process.env.REACT_APP_IMAGEBASE_URL+props.path+props.img} alt={props.img}/>
            </Suspense>
        </div>
      </div>
      <div className="py-4">
        <span dangerouslySetInnerHTML={{__html: props.textContent}} />
      </div>
   </ResizeScreenComposition>

  )
}

export default LandscapeCardDetailsLayout