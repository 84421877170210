
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import LandscapeCardDetailsLayout from "../../../layouts/LandscapeCardDetailsLayout";
import api from "../../../utils/axiosConfig";
const AcheivementListDetails = () => {
  const {id} = useParams();
  const [data, setData] = useState([])
  const fetchData = async (id) =>{
    try {
      const response = await api.get(`achievement-details?id=${id}`);
      setData(response.data)
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect( ()=>{
    fetchData(id);
  },[id])
  return (
    <>
       <LandscapeCardDetailsLayout  captionText={data.caption_text} createdAt={data.created_at} path='award-image/' img={data.award_image} textContent={data.article}/>
    </>
  )
}

export default AcheivementListDetails