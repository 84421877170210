import React from 'react'
import './ImageFrameLayout.css'

const ImageFrameLayout = (props) => {
  return (
        <div className="col-md-4 p-2">
            <div className="justify-content-center m-2 card-box">
                <img src={process.env.REACT_APP_IMAGEBASE_URL+props.path+props.img} alt={props.captionText} className='w-100'/>
                <p className='text-center'>{props.captionText}</p>
            </div>
        </div>
    )
}

export default ImageFrameLayout