import React from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import SectionComposition from '../../../layouts/SectionComposition'
import { Row,Col } from 'react-bootstrap'
import Heading from '../../../layouts/Heading'
import ContentComposition from '../../../layouts/ContentComposition'

const MissionVission = () => {
  return (
    <>
        <PageTitle title="Mission Vision" />
        <PageHeader headerTitle="Mission Vision"/>
        <SectionComposition>
           <ResizeScreenComposition>
              <Row>
                  <Col className='px-2'>
                      <Heading headingTitle = "Mission"/>
                      <ContentComposition>
                           At Khawja Yunus Ali Laboratory School and College, our mission is to provide a nurturing and stimulating educational environment that fosters the holistic development of our students. Our goal is to empower them with the knowledge, skills, and values necessary to become lifelong learners, responsible global citizens, and leaders in their respective fields.
                      </ContentComposition>
                  </Col>
                  <Col className='px-2'>
                      <Heading headingTitle = "Vision"/>
                      <ContentComposition>
                      Our vision at Khawja Yunus Ali Laboratory School and College is to be a leading educational institution that inspires excellence, innovation, and positive transformation in the lives of our students. We envision a future where our graduates are empowered to make a significant impact locally and globally, becoming catalysts for positive change in their communities and beyond.
                      </ContentComposition>
                  </Col>
              </Row>
           </ResizeScreenComposition>
        </SectionComposition>
    </>
  )
}

export default MissionVission