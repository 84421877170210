import React, { useEffect, useState } from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import SectionComposition from '../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import ContentComposition from '../../../layouts/ContentComposition'
import api from '../../../utils/axiosConfig'

const PrePrimary = () => {
  const [data, setData] = useState([]);
//Fetch function start
const fetchData = async () => {
  try {
    const res = await api.get(`row-admission-info?admission_section=${'4'}`);
    setData(res.data)
  } catch (error) {
    console.error(error);
  }
}
useEffect(() => {
  fetchData();
},[])

  return (
    <>
    <PageTitle title="Pre-Primary"/>
    <PageHeader headerTitle = "Pre-Primary"/>
    <SectionComposition>
       <ResizeScreenComposition>
          <ContentComposition>
              {
               <span dangerouslySetInnerHTML={{__html: data.content}} />
              }
          </ContentComposition>
       </ResizeScreenComposition>
    </SectionComposition>
  </>
  )
}

export default PrePrimary