import { NavLink } from "react-router-dom"
import { Card, Col } from "react-bootstrap"


const CardLayout = (props) => {
  return (
   <Col xl={4} className="px-3" data-aos='zoom-in'>
    <NavLink to={`${props.id}`} className="text-decoration-none text-muted">
        <Card style={{ width: '18rem', height: '24rem'}} className=''>
        <Card.Img variant="top" className='' src={props.img} />
        <Card.Body>
            <Card.Title className="text-center">{props.caption_text}</Card.Title>
            <Card.Text className='lead fs-6'>
               {props.summary}
            </Card.Text>
        </Card.Body>
        </Card>
    </NavLink>
   </Col>
  )
}

export default CardLayout