import React from 'react'
import SectionComposition from './SectionComposition'
import ResizeScreenComposition from './ResizeScreenComposition'

const TableComposition = (props) => {
  return (
      <SectionComposition>
         <ResizeScreenComposition>
               <div className="table-responsive" data-aos='zoom-in'>
                  <table className='table table-sm table-bordered table-striped'>
                        <thead>
                           <tr>
                           <th>#</th>
                           <th>{props.caption}</th>
                           <th>{props.dateOrYear}</th>
                           </tr>
                        </thead>
                        <tbody>
                        {props.children}
                        </tbody>
                  </table>
               </div>
         </ResizeScreenComposition>
      </SectionComposition>
  )
}

export default TableComposition