import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import LandscapeCardLayout from "../../../layouts/LandscapeCardLayout";
import api from "../../../utils/axiosConfig"
const AcheivementList = () => {
  const { id } = useParams();
  const [data, setData] = useState([])
  const fetchData = async (id) =>{
    try {
      const response = await api.get(`row-achievement_content?id=${id}`);
      setData(response.data)
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect( ()=>{
    fetchData(id);
  },[id])
  return (
    <div className="p-4">
        {
            data.map( (item, index)=> {
                return <LandscapeCardLayout key={index} img={item.award_image} captionText={item.caption_text} createdAt={item.created_at} articleContent={item.article} id={item.id}/>
            })
        }
    </div>
  )
}

export default AcheivementList