import { useEffect, useState } from "react"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import api from "../../../../utils/axiosConfig";



const Photo = () => {

  const [data, setData] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);


  //Fetch function start
const fetchData = async () => {
  try {
    const res = await api.get(`all-photo?gallery_type=${'1'}`);
    setData(res.data)
  } catch (error) {
    console.error(error);
  }
}

useEffect(() => {
  fetchData();
},[])


const viewImage = (index) => {
  setCurrentImageIndex(index);
};

const imgAction = (action) => {
  let index = currentImageIndex;
  if (action === "next-img") {
    setCurrentImageIndex(index + 1);
  }
  if (action === "previous-img") {
    setCurrentImageIndex(index - 1);
  }
  if (!action) {
    setCurrentImageIndex(null);
  }
};

  return (
    <>
      {currentImageIndex !== null && (
        <div className="w-100 vh-100 bg-dark d-flex position-fixed justify-content-center align-items-center overflow-hidden" style={{zIndex: "1000"}}>
          <button
            className="btn btn-sm btn-outline-info"
            style={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={() => imgAction()}
          >
            X
          </button>
          <button
            className="btn btn-sm btn-outline-info"
            onClick={() => imgAction("previous-img")}
            disabled={currentImageIndex === 0}
          >
            Previous
          </button>
          <img

            src={process.env.REACT_APP_IMAGEBASE_URL+'gallery/'+data[currentImageIndex].gallery_img}
            style={{ width: "auto", maxWidth: "90%", maxHeight: "90%" }}
            alt={data[currentImageIndex].gallery_img}
          />
          <button
            className="btn btn-sm btn-outline-info"
            onClick={() => imgAction("next-img")}
            disabled={currentImageIndex === data.length - 1}
          >
            Next
          </button>
        </div>
      )}
      <div className="p-2">
          <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                <Masonry gutter="20px" data-aos='zoom-in'>

                    {data.map((item, index) => (
                        <img
                            key={index}
                            src={process.env.REACT_APP_IMAGEBASE_URL+'gallery/'+item.gallery_img}
                            style={{width: "100%", display: "block", cursor: "pointer"}}
                            alt={item.gallery_img}
                            onClick={ ()=> viewImage(index)}
                        />
                    ))}

                </Masonry>
          </ResponsiveMasonry>
      </div>
    </>
  );
};

export default Photo;
