import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../layouts/PageHeader'
import PageTitle from '../../../../hooks/PageTitle'
import TableComposition from '../../../../layouts/TableComposition'
import api from '../../../../utils/axiosConfig'
import { NavLink } from 'react-router-dom'
import NoDataFound from '../../../../errors/NoDataFound'

const HolidayList = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get(`all-documents?document_name=${'holidayList'}`);
      setData(res.data)
    } catch (error) {
      console.error(error);
    }
  }
  useEffect( ()=> {
    fetchData();
    localStorage.setItem('previousPathname', 'document/')
  },[])
  return (
    <>
       <PageTitle title='HolidayList' />
       <PageHeader headerTitle='HolidayList'/>
       <TableComposition caption='Title' dateOrYear='Year'>
          {
            data.length === 0 ? (
              <tr>
                <td colSpan="3">
                  <NoDataFound errorCaption="No Holiday List is uploaded yet" />
                </td>
              </tr>
            ) :

            data.map((item, index) => 
            <tr key={index}>
                <td>{index + 1}</td>
                <td><NavLink to={`/view-pdf/${item.file}`} target="_blank">{item.caption_text}</NavLink></td>
                <td>{item.month_year}</td>
              </tr>
            )
          }
       </TableComposition>
    </>
  )
}

export default HolidayList