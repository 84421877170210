import React, { useEffect, useState } from 'react'
import api from '../../../../utils/axiosConfig';
import PageTitle from '../../../../hooks/PageTitle';
import PageHeader from '../../../../layouts/PageHeader';
import SectionComposition from '../../../../layouts/SectionComposition';
import ResizeScreenComposition from '../../../../layouts/ResizeScreenComposition';
import ContentComposition from '../../../../layouts/ContentComposition';

const Lab = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get(`row-student-life?content_type=${'lab'}`);
      setData(res.data)
    } catch (error) {
      console.error(error);
    }
  }
  useEffect( ()=> {
    fetchData();
  },[])


  return (
      <>
        <PageTitle title='Lab'/>
        <PageHeader headerTitle = 'Lab'/>
        <SectionComposition>
          <ResizeScreenComposition>
              <ContentComposition>
                <span dangerouslySetInnerHTML={{__html: data.content}} />
              </ContentComposition>
          </ResizeScreenComposition>
        </SectionComposition>
      </>
  )
}

export default Lab