import React from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import SectionComposition from '../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import CardLayout from '../../../layouts/CardLayout'
import { AchievementData } from './AchievementData'
import { Row } from 'react-bootstrap'



const Achievement = () => {
  return (
    <>
        <PageTitle title="Achievement" />
        <PageHeader headerTitle="Achievement"/>
        <SectionComposition>
        <ResizeScreenComposition>
            <div className="d-flex justify-content-center align-items-center">
              <Row>
              {
                AchievementData.map((item, index) => 
                  <CardLayout key={index} id={item.id} img={`/asset/img/achievement/` + item.img} caption_text = {item.category_name} summary={item.summary}/>
                )
              }
            </Row>
            </div>
        </ResizeScreenComposition>
        </SectionComposition>
    </>
  )
}

export default Achievement