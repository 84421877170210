import React from 'react'
import ProfileLazyImg from '../redux/ProfileLazyImg'

const MsgProfileLayout = (props) => {
    const capitalizeFirstLetter = (string) => {
        if (!string || string.length === 0) {
          return ''; // Return an empty string if the input is null, undefined, or empty.
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

  return (
        <div className="profile-card shadow p-4" data-aos='zoom-in'>
            <div className="d-flex justify-content-center ">
                <ProfileLazyImg src={process.env.REACT_APP_IMAGEBASE_URL+props.path+props.profile_img}  alt={props.profile_img}/>
            </div>
            <div className="position-relative mt-4 p-0 text-center">
                <h6>{props.name}</h6>
                <p>{capitalizeFirstLetter(props.position)}</p>

                <small style={{fontSize: '13px'}}>Khwaja Yunus Ali Laboratory School & College</small>
            </div>
        </div>
      )
}

export default MsgProfileLayout