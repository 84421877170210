import { Card,ListGroup } from "react-bootstrap"
import { NavLink } from "react-router-dom"

const NoticeComposition = (props) => {
  return (
    <Card style={{height:"466px"}} data-aos="fade-up">
            <Card.Header className='text-center fs-5 lead text-muted'>Upcoming Notice & Event</Card.Header>
            <Card.Body className='overflow-scroll'>
                <ListGroup as="ul">
                    {props.children}
                </ListGroup>
            </Card.Body>
         <Card.Footer className="d-flex justify-content-center align-items-center">
            <button className='btn btn-outline-success rounded-pill m-0 p-0'><NavLink className='text-decoration-none text-dark d-block' to="view-all-notices" style={{width: '200px'}}>View all</NavLink></button>
        </Card.Footer>
    </Card>
  )
}
export default NoticeComposition
