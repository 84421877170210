import React from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import SectionComposition from '../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import ContentComposition from '../../../layouts/ContentComposition'
import Heading from '../../../layouts/Heading'

const BackgroundKYALSC = () => {
  return (
    <>
      <PageTitle title='Background'/>
      <PageHeader headerTitle='Background of KYALSC'/>
      <SectionComposition>
          <ResizeScreenComposition>
              <ContentComposition>
                  <Heading headingTitle='“Introducing Khwaja Yunus Ali Laboratory School &amp; College’’'/>
                  <p>Khwaja Yunus Ali Laboratory School &amp; College (English Version). It is situated at
                    Enayetpur in Sirajganj district on the bank of river Jamuna. It is founded by Dr. M.M.
                    Amjad Hussain in 2009. The school covers an area of 1.76 acres. It has two big building
                    having several classrooms: - One is five storied with a U-shaped and 2 storied building
                    with L-Shape. There are nearly forty teachers in our School and College section.
                    KYALSC started its journey with a view of ensuring quality education to meet the
                    challenge of 21 st century. KYALSC is not only following the NCTB text book but also
                    provide extracurricular activities. Studying in this school is a great experience.
                    </p>
                    <br />
                    <Heading headingTitle='Educational Environment'/>
                    <p>
                        Kyalsc provides a nice environment and up-to date fundamental structure to develop the
                        student’s potentialities The school has a glorious past. The teachers are sincere and
                        cooperative. They are great and kind. The teachers are not only efficient and sincere
                        but also friendly and supportive. All the Teachers are highly qualified and well trained.
                        They work as a guide, guidance, facilitator, supplier and friendly behaviour with the
                        students. In junior section, Teachers play and take-care the students like as a Mother.
                        KYALSC believes that to make good result 3 conditions should be fulfilled like- effort of
                        the students, care of Teachers and monitoring of the guardians.
                    </p>
                    <br />
                    <Heading headingTitle='Extra-Curricular Activities'/>
                    <p>
                        The school is famous for its education, result, environment and extra-curricular
                        activities. Here students not only learn things but also prepare themselves for a greater
                        life. According to constitution, it is a well disciplined, well decorated and a perfect
                        school. The school is rich in various cultural programmes. There are also arrangements
                        for games, sports and Taekwondo. Students are divided into four houses which helps
                        the students to build up extracurricular activities. 
                    </p>
                    <br />
                    <p>
                        Such as-
                        <ul>
                            <li>General house--Debate, Act on Dialogue, Presentation and Spoken English.</li>
                            <li>Cultural house—Dance, Drama, Music, Art craft and Recitation.</li>
                            <li>Science House—Science project, computer lab and Library.</li>
                            <li>Physical House—Foodball, Cheese, Carrom, Ludu, Scout and Tiakwondo etc.</li>
                        </ul>
                    </p>
              </ContentComposition>
          </ResizeScreenComposition>
      </SectionComposition>
    </>
  )
}

export default BackgroundKYALSC