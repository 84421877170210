import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../layouts/PageHeader'
import SectionComposition from '../../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../../layouts/ResizeScreenComposition'
import { Row, Col } from 'react-bootstrap'
import api from '../../../../utils/axiosConfig'

const Video = () => {

  const [data, setData] = useState([]);


    //Fetch function start
const fetchData = async () => {
  try {
    const res = await api.get(`all-photo?gallery_type=${'3'}`);
    setData(res.data)
  } catch (error) {
    console.error(error);
  }
}

useEffect(() => {
  fetchData();
},[])


  return (
    <>
      <PageHeader headerTitle="Video Gallery"/>
      <SectionComposition>
         <ResizeScreenComposition>
              <Row>
                  {
                    data.map((item, index)=> 
                      <Col xl={4} className="p-2" key={index} data-aos='zoom-in'>
                          <div className="d-flex-column justify-content-center align-items-center">
                              <iframe className='border border-5 border-secondary w-100'
                                src={item.video_link}
                                frameBorder="1"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title={item.caption_text}
                                style={{height: '250px'}} />
                              <p className='text-center text-muted'>{item.caption_text}</p>
                          </div>
                      </Col>
                    )
                  }
              </Row>
         </ResizeScreenComposition>
      </SectionComposition>
    </>
  )
}

export default Video