import React from 'react'

const ContentComposition = (props) => {
  return (
        <div data-aos="fade-up" style={{fontWeight: '350'}}>
              {props.children}
        </div>
  )
}

export default ContentComposition