import React, { useEffect, useState } from 'react'
import PageTitle from '../../../hooks/PageTitle'
import PageHeader from '../../../layouts/PageHeader'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import SectionComposition from '../../../layouts/SectionComposition'
import api from '../../../utils/axiosConfig'
import ContentComposition from '../../../layouts/ContentComposition'

const BOT = () => {

  const [botData, setBotData] = useState([]);

  //Fetch function start
  const fetchData = async () => {
      try {
        const res = await api.get('governing-body');
        setBotData(res.data)
      } catch (error) {
        console.error(error);
      }
    }

    useEffect(() => {
      fetchData();
    },[])


  return (
    <>
        <PageTitle title="Governing Body" />
        <PageHeader headerTitle="Governing Body"/>
        <SectionComposition>
          <ResizeScreenComposition>
              <ContentComposition>
                  <span dangerouslySetInnerHTML={{__html: botData.content}} />
              </ContentComposition>
          </ResizeScreenComposition>
        </SectionComposition>
    </>
  )
}

export default BOT