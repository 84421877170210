export const CurrentData = [
    {
        caption: "Persistence",
        content: "Embrace challenges, stay determined, and never give up. Success comes to those who keep going."
    },
    {
        caption: "Resilience",
        content: "Life may throw curveballs, but remember that you possess the strength to bounce back and overcome any obstacle."
    },
  
    {
        caption: "Growth",
        content: "Embrace a growth mindset and strive for continuous improvement. Your potential knows no limits."
    },
    {
        caption: "Curiosity",
        content: "Stay curious and hungry for knowledge. Explore new ideas, ask questions, and seek understanding."
    },
    {
        caption: "Passion",
        content: "Find what ignites your soul and pursue it wholeheartedly. Passion fuels motivation and leads to incredible achievements."
    },
    {
        caption: "Dedication",
        content: "Devote yourself to your studies, extracurricular activities, and personal growth. Consistency and hard work breed success."
    },
    {
        caption: "Collaboration",
        content: "Recognize the power of teamwork and collaboration. Together, you can accomplish great things and support one another."
    },
    {
        caption: "Responsibility",
        content: "Take ownership of your actions and choices. Your future is shaped by the decisions you make today."
    },
    {
        caption: "Courage",
        content: "Step out of your comfort zone, embrace challenges, and have the courage to pursue your dreams fearlessly."
    },
    {
        caption: "Positivity",
        content: "Maintain a positive mindset even in the face of adversity. Your attitude can transform obstacles into opportunities."
    },
    {
        caption: "Balance",
        content: "Strive for a healthy balance between academics, social life, and self-care. Take time to rest and rejuvenate."
    },
    {
        caption: "Empathy",
        content: "Show compassion and empathy towards others. Small acts of kindness can have a profound impact on someone's day."
    },
    {
        caption: "Vision",
        content: "Envision your goals and dreams, and work towards them with clarity and purpose. Visualize your success."
    },
    {
        caption: "Integrity",
        content: "Uphold strong moral and ethical values. Let your actions be guided by honesty, fairness, and integrity."
    },
    {
        caption: "Celebration",
        content: "Celebrate your achievements, both big and small. Acknowledge your progress and use it as fuel for future accomplishments."
    },
]