import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import api from '../../../../utils/axiosConfig';
import SectionComposition from '../../../../layouts/SectionComposition';
import ResizeScreenComposition from '../../../../layouts/ResizeScreenComposition';
import ContentComposition from '../../../../layouts/ContentComposition';
const Details = () => {

  const { id } = useParams();
  const [data, setData] = useState([]);

  const  fetchRowById = async (id) =>{
    try{
        const res = await api.get(`row-front-box?id=${id}`);
        setData(res.data)
    }catch(error){
        console.error(error);
    }
}
  useEffect(() => {
    fetchRowById(id);
  }, [id]);

  return (
      <SectionComposition>
        <ResizeScreenComposition>
          <ContentComposition>
            {
              data.map((item, index) => (
                  <div key={index}>
                        <span dangerouslySetInnerHTML={{__html: item.content}} />
                  </div>
              ))
            }
          </ContentComposition>
        </ResizeScreenComposition>
      </SectionComposition>
  )
}

export default Details