import React, { Suspense, lazy } from 'react'
import SectionComposition from '../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition'
import ContentComposition from '../../../layouts/ContentComposition'
import { Col, Row } from 'react-bootstrap'
import { CurrentData } from './CurrentData'
import PageHeader from '../../../layouts/PageHeader'
import PageTitle from '../../../hooks/PageTitle'

const LazyImage = lazy(() => import('../../../redux/LazyImg'));



const Current = () => {


  return (

    <>
      <PageTitle title="Current Student" />
      <PageHeader headerTitle="Current Student"/>
      <SectionComposition>
      <ResizeScreenComposition>
          <ContentComposition>
              <Row>
                 <Col xl={8}>
                    {
                      CurrentData.map((item, index) => 
                        <div className="d-flex justify-content-start align-items-start" key={index}>
                          <b>{index + 1}.</b>
                          <b className='px-1'>{item.caption}: </b>
                          <p>{item.content}</p>
                        </div>
                      )
                    }
                 </Col>
                 <Col xl={4}>
                    <Suspense fallback={<div className='text-center'>Loading...</div>}>
                        <LazyImage src='/asset/img/students/current.jpg'/>
                      </Suspense>   
                 </Col>
              </Row>
          </ContentComposition>
      </ResizeScreenComposition>
    </SectionComposition>
    </>
  )
}

export default Current