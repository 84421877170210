import React, { lazy, Suspense,useEffect, useState } from 'react';
import {Carousel,Row,Col} from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import Heading from '../../../layouts/Heading';
import api from '../../../utils/axiosConfig';
import SectionComposition from '../../../layouts/SectionComposition';
import PageTitle from '../../../hooks/PageTitle';
import NoticeComposition from '../../../layouts/NoticeComposition';
import NoticeLayout from '../../../layouts/NoticeLayout';
import CardBoxLayout from '../../../layouts/CardBoxLayout';
import NoDataFound from '../../../errors/NoDataFound';
import { ChooseUsData } from '../../../data/ChooseUsData';
import styles from '../../../styles/web.module.css'
import { NavLink } from 'react-router-dom';
import ResizeScreenComposition from '../../../layouts/ResizeScreenComposition';
const LazyImage = lazy(() => import('../../../redux/LazyImg'));

const Home = () => {

    const [carouselData, setCarouselData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [newsData, setNewsData] = useState([]);
    const [noticeData, setNoticeData] = useState([]);

    const [frontBoxData, setFrontBoxData] = useState([]);

    //Fetch function start
    const fetchCarousel = async () => {
        try {
          const res = await api.get('web-carousel');
          setCarouselData(res.data)
        } catch (error) {
          console.error(error);
        }
      }
      const fetchEvent = async () => {
        try {
          const res = await api.get('web-event');
          setEventData(res.data)
        } catch (error) {
          console.error(error);
        }
      }

     const  fetchNews = async () =>{
        try{
            const res = await api.get('web-news');
            setNewsData(res.data)
        }catch(error){
            console.error(error);
        }
     } 
     const  fetchNotice = async () =>{
      try{
          const res = await api.get('web-notice');
          setNoticeData(res.data)
      }catch(error){
          console.error(error);
      }
   } 
     const  fetchFrontBox = async () =>{
      try{
          const res = await api.get('web-front-box');
          setFrontBoxData(res.data)
      }catch(error){
          console.error(error);
      }
   } 
     
     useEffect(() => {
        fetchCarousel();
        fetchEvent();
        fetchNews();
        fetchNotice();
        fetchFrontBox();
        localStorage.setItem('previousPathname', 'news-notice/')
      }, []);

  return (
    <main>
         <PageTitle title="Home" />
        {/* Home Carousel Start */}
         <Carousel pause={false} variant="dark" fade data-aos="zoom-in">
            { 
              carouselData.length === 0 ? (
                <NoDataFound errorCaption="No banner or slider is uploaded yet" />
              ) : (carouselData.map( (item, index) => {
                return (
                    <Carousel.Item key={index}>
                         <Suspense fallback={<div className='text-center'>Loading...</div>}>
                            <LazyImage src={process.env.REACT_APP_IMAGEBASE_URL+'carousel/'+item.img} alt={item.img}/>
                        </Suspense>
                 
                      <div className={styles.captionOverlay}>
                         <h5 className={styles.captionAnimation}>{item.caption_text}</h5>
                       </div>
                </Carousel.Item>
                )
                }))
            }
         </Carousel>
        {/* Home Carousel End */}     

        <ResizeScreenComposition>
            {/* Top News Start */}
            <SectionComposition bgColor="">
                <Row className="shadow" data-aos="zoom-in">
                    <Col lg={1}>
                        <div className="caption-bg text-white text-center py-3">Top News</div>
                    </Col>
                    <Col lg={11} className="d-flex justify-content-center align-items-center off-white">
                        <Marquee speed={50} pauseOnHover={true} className='cursor-pointer'>
                          {
                            newsData.length === 0 ? (
                              <NoDataFound errorCaption="No news is uploaded yet" />
                            ) : (newsData.map( (item, index)=> 
                            <div key={index} className='me-5'> 
                                <span dangerouslySetInnerHTML={{__html: item.caption_text}} />
                            </div>))
                          }
                        </Marquee>
                    </Col>   
                </Row>
              </SectionComposition> 
          {/* Top News End */}  


  {/* Event Slider and Notice Start */}
         <SectionComposition bgColor="">
            <Row>
               <Heading headingTitle = "Event, Program & Notice" />
                <Col xl={8} className="px-1" data-aos="zoom-in"> 
                        <Carousel variant="dark" fade>
                            { 
                            eventData.length === 0 ? (
                              <NoDataFound errorCaption="No program or event is uploaded yet" />
                                ) : (eventData.map( (item, index) => (
                                        <Carousel.Item key={index}>
                                            <Suspense fallback={<div className='text-center'>Loading...</div>}>
                                                <LazyImage src={process.env.REACT_APP_IMAGEBASE_URL+'carousel/'+item.img} alt={item.img} />
                                            </Suspense>
                                    
                                          <NavLink to="view-all-events-programs" title='Click to see all events and programs'>
                                                <div className={styles.captionOverlay}>
                                                    <h5 className={styles.captionAnimation}>{item.caption_text}</h5>
                                                </div>
                                          </NavLink>
                                        </Carousel.Item>
                                )))
                            }
                        </Carousel>

                </Col>    
                <Col xl={4} className="px-1"> 
                     <NoticeComposition>
                        {
                           noticeData.length === 0 ? (
                            <NoDataFound errorCaption="No notice is uploaded yet" />
                             ) : (noticeData.map( (item,index)=>{
                                return <NoticeLayout key={index} captionText = {item.caption_text} file= {item.file} createdAt={item.created_at}/>
                            }))
                        }
                     </NoticeComposition>       
                </Col>  
             </Row>   
        </SectionComposition>
     {/* Event Slider and Notice Start */}


        {/* Font Box Section Start */}
        <SectionComposition bgColor="">
            <Row>
            {
                frontBoxData.map( (item, index) => {
                    return <CardBoxLayout key={index} directoryName="frontbox/" id={item.id} icon={item.icon} bgImage="background.jpg" title={item.title} description={item.description}/>
                })
            }

            </Row>
          </SectionComposition>
        {/* Font Box Section End */}

        {/* Why Choose us Section Start */}
        <SectionComposition bgColor="">
            <Heading headingTitle = "Why Choose Us" />
            <Row>
            {
                ChooseUsData.map( (item, index) => {
                    return <CardBoxLayout key={index} directoryName="choose-us/" icon={item.icon} bgImage="background.jpg" title={item.title} description={item.article}/>
                })
            }
            </Row>
          </SectionComposition>
        {/* Why Choose us Section End */}
      </ResizeScreenComposition>
      {/* Width Screen end Taq  */}
    </main>
  )
}

export default Home