import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import api from '../../../utils/axiosConfig';
import PageTitle from '../../../hooks/PageTitle';
import PageHeader from '../../../layouts/PageHeader';
import TableComposition from '../../../layouts/TableComposition';
import NoDataFound from '../../../errors/NoDataFound';


const Academic = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await api.get(`all-documents?document_name=${'academicResult'}`);
      setData(res.data)
    } catch (error) {
      console.error(error);
    }
  }
  useEffect( ()=> {
    fetchData();
    localStorage.setItem('previousPathname', 'document/')
  },[])
  return (
    <>
       <PageTitle title='Academic Result' />
       <PageHeader headerTitle='Academic Result'/>
       <TableComposition caption='Title' dateOrYear='Year'>
          {
            data.length === 0 ? (
              <tr>
                <td colSpan="3">
                  <NoDataFound errorCaption="No Academic Result is uploaded yet" />
                </td>
              </tr>
            ) :

            data.map((item, index) => 
              <tr>
                <td>{index + 1}</td>
                <td><NavLink to={`/view-pdf/${item.file}`} target="_blank">{item.caption_text}</NavLink></td>
                <td>{item.month_year}</td>
              </tr>
            )
          }
       </TableComposition>
    </>
  )
}

export default Academic