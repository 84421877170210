import React, { useEffect, useState } from 'react'
import SectionComposition from '../../../../layouts/SectionComposition'
import ResizeScreenComposition from '../../../../layouts/ResizeScreenComposition'
import ContentComposition from '../../../../layouts/ContentComposition'
import PageTitle from '../../../../hooks/PageTitle'
import PageHeader from '../../../../layouts/PageHeader'
import Heading from '../../../../layouts/Heading'
import MsgProfileLayout from '../../../../layouts/MsgProfileLayout'
import api from '../../../../utils/axiosConfig'

const HeadTeacher = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
          const res = await api.get(`row-message?position=${'head-teacher'}`);
          setData(res.data)
        } catch (error) {
          console.error(error);
        }
      }
      useEffect( ()=> {
        fetchData();
      },[])

  return (
     <>
        <PageTitle title='Head Teacher Message' />
        <PageHeader headerTitle='Message of Head Teacher'/>
        <SectionComposition>
            <ResizeScreenComposition>
                     <div className="row">
                         <div className="col-md-3 px-2">
                             <MsgProfileLayout path='authority/' profile_img={data.profile_img} name={data.name} position={data.position}/>
                         </div>
                         <div className="col-md-9 px-2">
                             <Heading headingTitle='Message from Head Teacher'/>
                             <ContentComposition>
                             <div className="shadow p-4">
                                 <p className='line-height'> 
                                      <span dangerouslySetInnerHTML={{__html: data.content}} />
                                 </p>
                             </div>
                             </ContentComposition>
                         </div>
                     </div>
            </ResizeScreenComposition>
        </SectionComposition>
     </>
  )
}

export default HeadTeacher