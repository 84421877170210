export const FooterQuickLinkData = [
    // {
    //     site: "Ministry of Education",
    //     link: "http://moedu.gov.bd/"
    // },
    {
        site: "Rajshahi Education Board",
        link: "https://rajshahiboard.gov.bd/"
    },

    {
        site: "NCTB",
        link: "http://www.nctb.gov.bd/"
    },
    {
        site: "Education Boards",
        link: "http://www.educationboard.gov.bd/"
    },
    {
        site: "Directorate of Primary Education",
        link: "http://www.dpe.gov.bd/"
    },
    {
        site: "DSHE",
        link: "http://www.dshe.gov.bd/"
    },
    {
        site: "Shikkhok Batayon",
        link: "https://teachers.gov.bd/"
    }
    

    
];