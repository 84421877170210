import { useState, useEffect } from 'react'
import api from '../../../utils/axiosConfig';
import AllNoticeComposition from '../../../layouts/AllNoticeComposition';
import AllNoticeLayout from '../../../layouts/AllNoticeLayout'


const ViewAllNotices = () => {

    const [data , setData] = useState([]);

    const  fetchAllNotice = async () =>{
        try{
            const res = await api.get('all-notice');
            setData(res.data)
        }catch(error){
            console.error(error);
        }
     } 

     useEffect( ()=> {
        fetchAllNotice();
     },[])

  return (
    <AllNoticeComposition>
        {
            data.map( (item, index) => (
                <AllNoticeLayout key={index} sl={index + 1} captionText = {item.caption_text} file={item.file} createdAt = {item.created_at} />
            ))
        }
    </AllNoticeComposition>
  )
}

export default ViewAllNotices